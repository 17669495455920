<template>
  <div
    class="overflow-hidden relative rounded shadow-card"
    style="border: 1px solid #eff2fd"
    v-on="$listeners"
  >
    <credit-card-3
      :card-exists="false"
    />
    <div class="absolute bg-blue-800 bg-opacity-50 grid inset-0 place-items-center">
      <sm-loader-white />
    </div>
  </div>
</template>

<script>
export default {
  name: "CardEmpty",
  computed: {
    creditLimit() {
      return this.user?.profile?.credit_limit;
    },
  },
  methods: {
    continueOnboarding() {
      this.$root.$emit('open-onboarding');
    },
  },
};
</script>
