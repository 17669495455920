<template>
  <div>
    <h3 class="text-xl font-bold mb-8 text-gray-700">
      Active cards
    </h3>

    <div class="flex flex-wrap gap-4 md:flex-no-wrap lg:flex-wrap xl:flex-no-wrap">

      <div class="w-full md:w-6/10 lg:w-full xl:w-6/10">
        <CardLoading v-if="personalCards.loading" />
        <!-- Has Card -->
        <template v-else-if="hasCards">
          <Card
            :card="creditCard"
            @activate-card="reload"
            @get-personal-cards="reload"
            @request-pin="reload"
          />
        </template>
        <!-- Card Requested -->
        <CardRequested v-else-if="hasCardRequest" />
        <!-- No Card -->
        <CardEmpty v-else />
      </div>

      <Bills
        :card="creditCard"
        class="
          w-full md:w-4/10 lg:w-full xl:w-4/10
        "
      />

    </div>

    <RepaymentReminder
      v-if="creditCard"
      :next-credit-card-repayment="nextCreditCardRepayment"
      :credit-card-grace-period="creditCardGracePeriod"
    />

    <express
      v-if="expressVerificationMethod && !bankStatementUploaded"
    />

  </div>
</template>

<script>
export default {
  name: "Cards",
  components: {
    Bills: () => import('./Bills'),
    RepaymentReminder: () => import("./RepaymentReminder"),
    Card: () => import('./Card'),
    CardEmpty: () => import('./CardEmpty'),
    CardLoading: require('./CardLoading').default,
    CardRequested: () => import('./CardRequested'),
  },
  data() {
    return {};
  },
  computed: {
    creditCard() {
      return this.personalCards.data[0];
    },
    creditCardGracePeriod() {
      if (!this.user?.personal_statement?.extra?.grace_due_date) {
        return "--"
      }
      return this.$moment(
        this.user?.personal_statement?.extra?.grace_due_date
      ).format("MMM Do YYYY");
    },
    hasCards() {
      return this.personalCards.data?.length;
    },
    hasCardRequest() {
      return this.user?.card_request;
    },
    isCardReady() {
      return this.hasCardRequest?.status === "ready";
    },
    nextCreditCardRepayment() {
      if (!this.user?.personal_statement?.extra?.payment_due_date) {
        return "--"
      }
      return this.$moment(
        this.user?.personal_statement?.extra?.payment_due_date
      ).format("MMM Do YYYY");
    },
    personalCards() {
      return this.resources.personalCards;
    },
  },
  beforeMount() {
    this.getCards(true);
  },
  mounted() {
    this.emitSelectedCard();
  },
  watch: {
    creditCard() {
      this.emitSelectedCard();
    },
  },
  methods: {
    emitSelectedCard() {
      this.$emit('select-card', this.creditCard);
    },
    getCards(forced = false) {
      this.loadResource('personalCards', forced);
    },
    reload() {
      this.getCards(true);
    }
  },
};
</script>
